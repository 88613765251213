import React from "react";
import { concatClassNames as cn } from "@sys42/utils";

import Layout from "../../layouts/layout";

import { HeaderTitle } from "../../components/HeaderTitle";
import { HtmlContent } from "../../components/HtmlContent";
import { WavySeparator } from "../../components/WavySeparator";

import * as helperStyles from "../helpers.module.css";
import {
  ButtonPrimaryLarge,
  addUtmParams,
  urlTesterRegistration,
} from "@userbrain/website-ui";

const Sales = () => (
  <Layout title={"Userbrain Tester [English]"}>
    <article>
      <HeaderTitle titleWidth={"13em"}>Userbrain Tester [English]</HeaderTitle>

      <WavySeparator style={{ paddingTop: "var(--spacing-md)" }} />

      <div className={helperStyles.container}>
        <HtmlContent
          className={cn(helperStyles.spacingMd, helperStyles.widthHtmlContent)}
        >
          <h2>Description</h2>
          <p>
            At Userbrain, we are always looking for individuals who can help us
            improve the user experience of websites, prototypes, and mobile
            apps. As a Userbrain Tester, you will play a crucial role in our
            clients' user research by providing valuable feedback on various
            digital products. This is a unique opportunity to earn money from
            home, on your own schedule, without any specific experience
            required.
          </p>

          <h2>About the Job</h2>
          <ul>
            <li>Location: Remote (worldwide) 🌎</li>
            <li>Compensation: $5 per completed test 💸</li>
          </ul>

          <h2>How does it work?</h2>
          <ol>
            <li>
              Sign up and download our Userbrain Recorder for Google Chrome,
              iOS, or Android to start testing websites.
            </li>
            <li>
              Speak your thoughts while going through a set of tasks for about 5
              to 20 minutes.
            </li>
            <li>Receive new tests and get paid $5 per test via PayPal.</li>
          </ol>

          <h2>What happens after you apply?</h2>
          <ol>
            <li>
              You’ll be asked to record a Qualification Test. Don’t worry, we’ll
              give you a tour and a guide first, so you know exactly how
              everything works.
            </li>
            <li>
              We then review your video and give feedback on how and what you
              might improve in future tests.
            </li>
            <li>
              You will start receiving paid tests if approved as a Userbrain
              tester.
            </li>
          </ol>

          <h2>Requirements</h2>
          <ul>
            <li>
              You have a reliable internet connection and a device to test on
              (computer, smartphone, or tablet).
            </li>
            <li>You are fluent in English.</li>
            <li>
              You can articulate your thoughts clearly and provide constructive
              feedback.
            </li>
          </ul>

          <h2>About the application process</h2>
          <ol>
            <li>We receive your application</li>
            <li>You complete a Qualification Test</li>
            <li>We review your test and provide feedback</li>
            <li>If approved, you start receiving paid tests</li>
          </ol>

          <p style={{ marginTop: "3rem" }}>
            <ButtonPrimaryLarge
              customTag={"a"}
              href={addUtmParams(urlTesterRegistration, "website", "careers")}
            >
              Become a Userbrain Tester
            </ButtonPrimaryLarge>
          </p>
        </HtmlContent>
      </div>
    </article>
  </Layout>
);

export default Sales;
